import { GET_COMPANY_LISTS, ROLES_FOR_SITE_URL, SET_LOADER, SET_TOAST_INFORMATION } from "./types";
const InitialState = {
  companyLists: [],
  toastInfo: {
    type: '',
    message: '',
    status: false,
    isTransition: false,
  },
  SiteUrlLists: [],
  loading: false
};

const GlobalReducer: any = (state: any = InitialState, action: any) => {
  switch (action?.type) {
    case GET_COMPANY_LISTS:
      return {
        ...state,
        companyLists: action?.payload?.data?.data
      }
    case SET_TOAST_INFORMATION:
      return {
        ...state,
        toastInfo: {
          ...action.payload,
          isTransition: action.payload?.isTransition || false,
        },
      };
    case ROLES_FOR_SITE_URL:
      return {
        ...state,
        SiteUrlLists: action?.payload?.roles
      };
      case SET_LOADER:
        return {
          ...state,
          loading: action?.payload
        };

    default:
      return state;
  }
};
export default GlobalReducer;
