import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../../Components/FormControlls/TextInput';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Forgotpassword } from '../../redux/action';
import { EmailValidation } from '../../helpers/validation';
import { AppDispatch } from '../../store';

const ForgotPassword = () => {
  const [forgotres, setForgotResponse] = useState<any>('')
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState<any>(false);
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleFormSubmit = async (e: any) => {
    if (!email) {
      setIsError(true);
      return;
    }
    if (!EmailValidation(email)) setIsError(true)
    else dispatch(Forgotpassword({
      email: email

    })).then((res: any) => {
      setForgotResponse(res);
      if (isEmpty(res?.data?.errorMessage)) {
        setEmail("");
        // navigate(`/ResetPassword`, { state: { email } })
      }
    });
  };

  const getValidationMessage = () => {
    if (isError) {
      if (!email) {
        return 'The Email field is required.';
      }
      if (!EmailValidation(email)) {
        return 'Please enter valid email address.';
      }
    }
    return ''
  }

  const onSubmitFunction = (e: any) => {
    e.preventDefault();
    handleFormSubmit(e)
  }

  return (
    <div className='flex flex-col text-primarytext '>
      <form onSubmit={onSubmitFunction}>

        {forgotres.status === 200 && forgotres?.data?.responseMessage.toLowerCase() !== 'failure' ? (<div>
          <div className="flex relative top-2">
            <h2 className="text-2xl font-bold mb-7 text-custom-colormt-2">Please check your emails</h2>
          </div>
          <p className='text-sm text-primarylight item-start mb-7'>
            We have sent an email with <br />
            instructions to reset your password.
          </p>
          <div className='text-sm text-primarylight'>
            Did not receive the email? Please wait for a few <br />minutes, check your spam or junk folders, or
            <span className='text-manhattan700 text-sm m-1 cursor-pointer' onClick={() => setForgotResponse('')}>{`${'try again'} >`}</span>
          </div>
        </div>
        ) : (

          <>
            <div className='flex  flex-row  w-96 min-width-30'>
              <span className='text-2xl font-bold mb-9 item-start'>Forgot your Password?</span>
            </div>
            <p className='text-sm text-primarylight mb-7'>Enter your email below to receive a link to reset your password.</p>
            <TextInput
              label={'EMAIL ADDRESS'}
              value={email}
              className="w-96 mb-3  min-width-30"
              onChange={(value: any) => setEmail(value)}
              onError={isError}
              helperText={getValidationMessage()} />
            <div className='flex py-1'></div>
            <div className='flex flex-row item-center  py-4'>
              <button
                className="bg-primary  text-sm text-white  iborder  border-darkBlueBorder  font-bold py-3 px-6 h-11 rounded-md focus:outline-none focus:shadow-outline min-w-[120px]"
                type="submit"
                onClick={onSubmitFunction}
              >
                SUBMIT
              </button>
              <button
                className="  text-sm border border-brdrbtn1 font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline ml-3 cancel-btn "
                onClick={() => navigate('/', { replace: true })}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default ForgotPassword;