/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import LoginForm from '../Pages/Login/form';
import SignForm from '../Pages/Signup/form'
import "../Styles/Auth.scss";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Dashboard from '../Components/Dashboard';
import VerifyEmail from '../Pages/VerifyEmail';
import ResetPassword from '../Pages/ResetPassword';
import ForgotPassword from '../Pages/Forgot';
import Toast from '../Components/Toast';
import Loader from '../Components/Loader';
import config from '../config';
import metadata from '../metadata.json'
import { staticTitle } from '../helpers/static';

const AppRoute = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const initLoaded = useRef(false);
  const isVerifyEmail = pathname?.toLowerCase() === '/verify';
  const [cookies, setCookie] = useCookies(['SiteToAccess', ".AspNet.ApplicationCookie", "ASP.NET_SessionId"]);

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }
  const logout = searchParams.get('logout');
  
  const renderTitle = () => {
    document.title = staticTitle[pathname] || 'Log in - Childcare Management System';
  };

  useEffect(() => {
    renderTitle()
  }, [pathname]);

  useEffect(() => {
    if (!initLoaded.current) {
      initLoaded.current = true;
      if (localStorage.getItem('UserAccessToken') !== null) {
        if(logout) {
          setCookie("SiteToAccess", null, {path: "/"});
          setCookie(".AspNet.ApplicationCookie", null, {path: "/"});
          setCookie("ASP.NET_SessionId", null, {path: "/"});
          localStorage.clear();
        }           
        else window.location.replace(`${config.HomeUrl}/v1/Home`);
      }
    }
  }, [logout])

  return <div className="flex min-h-screen">
    <Loader />
    <Toast />
    <div className={classNames(!isVerifyEmail ? 'max-w-2xl' : 'w-full', " mx-auto flex flex-col justify-between")}>
      {!isVerifyEmail && <Header />}
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/Login" element={<LoginForm />} />
        <Route path="/Register" element={<SignForm />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
      </Routes>
      {!isVerifyEmail && <Footer />}
    </div>
    {!isVerifyEmail && <div className="md:block md:w-1/2">
      <div className="custom-image" />
    </div>}
    <div className="fixed text-primary text-sm top-full-20 z-99 right-5">
      {metadata?.buildVersion}
    </div>
  </div>
}
const AuthLayout: React.FC = () => {
  return (
    <BrowserRouter basename='/authentication'>
      <AppRoute />
    </BrowserRouter>
  );
};

export default AuthLayout;