import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link,useNavigate, useSearchParams } from "react-router-dom"
//Components
import TextInput from "../../Components/FormControlls/TextInput"
//Redux
import { ResetPasswordAction } from "../../redux/action"
//helper
import { Dont_Start_number, FindCapitalLetter, IncludeSpecialCharecters, ResetPasswordValidation } from "../../helpers/validation"
import { isEmpty } from "lodash"

const ResetPassword = () => {
  const navigate = useNavigate();
  const [ReqObj, setReqObj] = useState({
    password: '',
    confirmPassword: '',
    code:''
  })

  const [resetResponse, setResetResponse] = useState<any>('')
  const [validateState, setState] = useState({ ResetError: {} })
  const { ResetError }: any = validateState;
  const dispatch = useDispatch<any>()

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const username = searchParams.get('username');

  const AllowResetPassword = () => {
    const { isValid, errors } = ResetPasswordValidation(ReqObj);
    setState({
      ResetError: errors
    })
    return isValid
  }

  const handleInputChange = (type: string, value: any) => {
    setState({ ResetError: { ...ResetError, [type]: "" } })
    if(type === "code"){
      if((/^\d*$/.test(value))) setReqObj({ ...ReqObj, [type]: value })
    } else{
      setReqObj({ ...ReqObj, [type]: value })
    }
  };

  const ResetAction = () => {
    if (AllowResetPassword()) {
      let postReq: any = {
        email: username,
        code: code,
        password: ReqObj?.password
      }
      dispatch(ResetPasswordAction(postReq)).then((res: any) => {
        if (res.data.statusCode === 200) {
          setResetResponse(res);
        }
      })
    }
  }

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const onSubmitFunction = (e: any) => {
    e.preventDefault();
    ResetAction()
  }

  return (
  <div className='flex flex-col text-primarytext max-w-xl'>
    <form onSubmit={onSubmitFunction}>
      {resetResponse.status === 200 && resetResponse?.data?.responseMessage.toLowerCase() !== 'failure'? (<>
        <div className="flex relative top-2">
          <h2 className="text-2xl font-bold mb-7 text-custom-colormt-2">Password reset</h2>
        </div>
        <div className='text-primary text-sm item-start mb-7 '>
            Your password has been successfully reset
          </div>
          <Link className='text-manhattan700 text-sm' to="/Login">{`${'Sign In'} >`}</Link>
           </> ) : (
        <>
        <div className='flex  flex-col py-8 w-96 '>
          <span className='text-2xl font-bold py-8 '>Create a new password</span>
          <span className='text-sm  text-primarylight '>Enter and confirm your new password below.</span>
        </div><div className="flex flex-row w-96 ">
            <div className="w-1/2">
              <TextInput
                password={true}
                value={ReqObj?.password}
                onChange={(value: any) => handleInputChange("password", value)}
                label='PASSWORD'
                onError={!isEmpty(ResetError?.password)}
                helperText={!isEmpty(ResetError?.password) ? ResetError?.password : ''} />
            </div>
            <div className="w-1/2">
              <TextInput
                password={true}
                className='ml-2'
                value={ReqObj?.confirmPassword}
                onChange={(value: any) => handleInputChange("confirmPassword", value)}
                label='CONFIRM PASSWORD'
                onError={!isEmpty(ResetError?.confirmPassword)}
                helperText={!isEmpty(ResetError?.confirmPassword) ? ResetError?.confirmPassword : ''} />
            </div>
          </div><ul className='flex flex-col px-4 py-4'>
            <li className={classNames(ReqObj?.password.length < 8 ? 'text-manhattan700' : 'text-bluegraylight', 'text-sm')}> Minimum 8 characters.</li>
            <li className={classNames(Dont_Start_number(ReqObj?.password) ? 'text-manhattan700' : 'text-bluegraylight', 'text-sm')}> Dont start with a number.</li>
            <li className={classNames(IncludeSpecialCharecters(ReqObj?.password) ? 'text-manhattan700' : 'text-bluegraylight', 'text-sm')}>Include a special character.</li>
            <li className={classNames(FindCapitalLetter(ReqObj?.password) ? 'text-manhattan700' : 'text-bluegraylight', 'text-sm')}>one CAPITAL letter.</li>
          </ul><div className='flex flex-row '>
            <button
              className="bg-custom-color  text-sm text-white  font-bold py-2 px-6 h-11 rounded-md focus:outline-none focus:shadow-outline"
              type="submit"
              onClick={onSubmitFunction}
            >
              SUBMIT
            </button>
            <button
              className="outline-white-btn text-sm border border-body font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline ml-3"
              onClick={() => navigate('/', { replace: true })}
            >
              Cancel
            </button>
          </div>
          </>
      )}

    </form>
  </div>
)}

export default ResetPassword