import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//components
import TextInput from '../../Components/FormControlls/TextInput';
import KendoSelector from '../../Components/FormControlls/KendoSlector';
//helpers
import { Dont_Start_number, FindCapitalLetter, IncludeSpecialCharecters, SignUpValidation } from '../../helpers/validation';
//redux
import { CreateUserInSignUp, GetCompanyLists, setLoader } from '../../redux/action';
import { isEmpty, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';


const Form: React.FC = () => {

  const tempObj = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    centreName: "",
    siteAccess: "",
    // userName: ""
  }

  const navigate = useNavigate()
  const [formData, setFormData] = useState(tempObj)
  const [validateState, setState] = useState({ signUpError: {} })
  const [selectedCompany, setSelectedCompany] = useState<any>({ label: 'Select company', value: '' })
  const [selectedCenter, setSelectedCenter] = useState<any>({ label: 'Select centre', value: '' })
  const [selectedSite, setSelectedSite] = useState<any>({ label: 'Select site', value: '' })
  const { signUpError }: any = validateState;
  const dispatch = useDispatch<any>();
  const [registerStatus, setRegisterStatus] = useState<any>('');
  const initLoaded = useRef(false);
  useLayoutEffect(() => {
    if (!initLoaded.current) {
      initLoaded.current = true;
      dispatch(GetCompanyLists())
    }
  }, []);

  useEffect(() => {
    setSelectedCenter({ label: 'Select centre', value: '' })
  }, [selectedCompany])

  useEffect(() => {
    setSelectedCompany({ label: 'Select company', value: '' })
    setSelectedCenter({ label: 'Select centre', value: '' })
  }, [selectedSite])

  const handleInputChange = (type: string, value: any) => {
    setState({ signUpError: { ...signUpError, [type]: "" } })
    setFormData({ ...formData, [type]: value })
  };

  const AllowSignUp = () => {
    const formDataValidation = {
      ...formData,
      centreid: selectedCenter?.value || "",
      companyid: selectedCompany?.value || "",
      siteAccess: selectedCompany?.siteAccess || "",
    }
    const { isValid, errors } = SignUpValidation(formDataValidation);
    setState({
      signUpError: errors
    })
    return isValid
  }

  const GetSiteCompanyCentreLists = useSelector((state: any) => state?.global?.companyLists);

  // Get siteLists
  // const GetUniqBySiteAccess = uniqBy(GetSiteCompanyCentreLists, 'siteAccess')

  // Get companyLists
  const CompanyListsBasedOnSiteAccess = (GetSiteCompanyCentreLists || []);
  // .filter((el: any) => {
  //   return el?.siteDatabase === selectedSite.value
  // })

  const CompanyListsAvoidNullValues = (CompanyListsBasedOnSiteAccess || []).filter((el: any) => {
    return !(el?.companyName === null || el?.companyName === "")
  })
  const GetUniqByCompanyId = uniqBy(CompanyListsAvoidNullValues, 'companyName');
  // Get centreLists
  const centreListsBasedOnSelectedCompany = (CompanyListsAvoidNullValues || []).filter((el: any) => {
    return el?.companyName === selectedCompany.label
  })
  const GetUniqByCentreId: any = uniqBy(centreListsBasedOnSelectedCompany, 'centreID').filter((el: any) => {
    return !(el?.name === null || el?.name === "")
  })

  const HandleSaveClick = () => {
    let postReq = {
      // "username": formData?.userName,
      "email": formData?.email,
      "password": formData?.password,
      "firstname": formData?.firstName,
      "lastname": formData?.lastName,
      "mobilenumber": formData?.mobileNumber,
      "centreid": selectedCenter.value,
      "companyid": selectedCompany.value,
      "siteAccess": selectedCompany.siteAccess,
      "roles": ""
    }
    if (AllowSignUp()) {
      dispatch(setLoader(true));
      dispatch(CreateUserInSignUp(postReq)).then((res: any) => {
        dispatch(setLoader(false));
        if (res?.status === 200 && res?.data?.responseMessage !== 'Failure' && res?.data?.errorMessage === null || res?.data?.errorMessage === "") {
          setRegisterStatus(res);
          setFormData(tempObj)
          setSelectedCompany({})
          setSelectedCenter({})
          setSelectedSite({})
        }
      })
    }
  }

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const onSubmitFunction = (e: any) => {
    e.preventDefault();
    HandleSaveClick();
  }

  return (
    <form onSubmit={onSubmitFunction} className="flex flex-wrap content-center w-full text-primarytext  min-w-[480px] max-w-[480px] ">


      {registerStatus.status === 200 && registerStatus?.data?.responseMessage.toLowerCase() !== 'failure' ? (<div>
        <div className="flex relative top-2">
          <h2 className="text-2xl font-bold mb-7 text-custom-colormt-2"> Thank you for registering</h2>
        </div>

        <div className='text-primary text-sm item-start  mb-7'>
          Please click the link in the email we have just <br /> sent to confirm your details.
        </div>
        <Link className='text-manhattan700 text-sm' to="/Login">{`${'Sign In'} >`}</Link>
      </div>
      ) : (
        <>
          <div className="flex relative top-7">
            <h2 className="text-2xl font-bold mb-9 text-custom-colormt-2">Create a New Account</h2>
          </div>
          <div className="flex mt-4 w-full">
            <div className="w-1/2">
              <TextInput
                label={'FIRST NAME'}
                value={formData.firstName}
                onChange={(value: any) => handleInputChange("firstName", value)}
                className='m-1'
                placeHolder='First name'
                required={true}
                onError={!isEmpty(signUpError?.firstName)}
                helperText={!isEmpty(signUpError.firstName) ? signUpError.firstName : ''}
              />
            </div>
            <div className="w-1/2">
              <TextInput
                label={'LAST NAME'}
                value={formData.lastName}
                onChange={(value: any) => handleInputChange("lastName", value)}
                className='m-1 '
                placeHolder='Last name'
                required={true}
                onError={!isEmpty(signUpError?.lastName)}
                helperText={!isEmpty(signUpError.lastName) ? signUpError.lastName : ''}
              />
            </div>
          </div>
          <div className="flex flex-wrap w-full flex-row mt-2 ">
            <div className="w-2/3">
              <TextInput
                label={'EMAIL ADDRESS '}
                value={formData.email}
                onChange={(value: any) => handleInputChange("email", value)}
                className='m-1'
                placeHolder='Email'
                required={true}
                onError={!isEmpty(signUpError?.email)}
                helperText={!isEmpty(signUpError.email) ? signUpError.email : ''}
              />
            </div>
            <div className="w-1/3">
              <TextInput
                label={'MOBILE NUMBER'}
                value={formData.mobileNumber}
                onChange={(value: any) => handleInputChange("mobileNumber", value)}
                className='m-1'
                placeHolder='Mobile number'
                required={true}
                onError={!isEmpty(signUpError?.mobileNumber)}
                helperText={!isEmpty(signUpError.mobileNumber) ? signUpError.mobileNumber : ''}
                AllowOnlyNumber={true}
              />
            </div>
          </div>
          {/* <div className="flex flex-wrap mt-2">
    <div className="w-full px-1">
      <div className='flex flex-row'>
        <label className="block text-xs font-semibold bg-kendo">SITE NAME</label>
        {<span className='-right-4 -mt-0\.5 left-auto text-manhattan700 ml-2 ' >*</span>}
      </div>
      <KendoSelector
        defaultItem='Select site'
        value={selectedSite}
        DropdownContent={(GetUniqBySiteAccess || []).map((el: any) => ({ label: el?.siteAccess, value: el.siteDatabase }))}
        dataItemKey='value'
        textField='label'
        onChangeFunction={((event: any) => {
          setSelectedSite(event.value);
          setState({ signUpError: { ...signUpError, siteAccess: "" } })
        })}
        controlled={!isEmpty(GetUniqBySiteAccess) ? undefined : false}
        onError={!isEmpty(signUpError?.siteAccess)}
        helperText={!isEmpty(signUpError.siteAccess) ? signUpError.siteAccess : ''}
      />
    </div>
  </div> */}
          <div className="flex flex-wrap mt-2 w-full">
            <div className="w-full px-1">
              <div className='flex flex-row'>
                <label className="block text-xs font-semibold bg-kendo">COMPANY NAME</label>
                {<span className='-right-4 -mt-0\.5 left-auto text-manhattan700 ml-2 ' >*</span>}
              </div>
              <KendoSelector
                defaultItem='Select company'
                value={selectedCompany}
                DropdownContent={(GetUniqByCompanyId || []).map((el: any) => ({ ...el, label: el?.companyName, value: el.companyID, siteDatabase: el.siteDatabase }))}
                dataItemKey='label'
                textField='label'
                onChangeFunction={((event: any) => {
                  setSelectedCompany(event.value);
                  setState({ signUpError: { ...signUpError, companyName: "" } })
                })}
                controlled={!isEmpty(GetUniqByCompanyId) ? undefined : false}
                onError={!isEmpty(signUpError?.companyName)}
                helperText={!isEmpty(signUpError.companyName) ? signUpError.companyName : ''}
              />
            </div>
          </div>
          <div className="flex flex-wrap mt-2 px-1 w-full">
            <div className="w-full">
              <div className='flex flex-row'>
                <label className="block text-xs font-semibold bg-kendo">CENTRE NAME</label>
                {<span className='-right-4 -mt-0\.5 left-auto text-manhattan700 ml-2 ' >*</span>}
              </div>
              <KendoSelector
                defaultItem='Select centre'
                value={selectedCenter}
                DropdownContent={((GetUniqByCentreId || [])).map((el: any) => ({ label: el?.name, value: el.centreID }))}
                onChangeFunction={((event: any) => {
                  setSelectedCenter(event.value);
                  setState({ signUpError: { ...signUpError, centreName: "" } })
                })}
                dataItemKey='value'
                textField='label'
                controlled={!isEmpty(GetUniqByCentreId) ? undefined : false}
                onError={!isEmpty(signUpError?.centreName)}
                helperText={!isEmpty(signUpError.centreName) ? signUpError.centreName : ''}
              />
            </div>
          </div>
          {/* <div className="flex flex-col mt-2 ">
            <div className="w-2/3 ">
              <TextInput
                label={'USERNAME'}
                value={formData.userName}
                onChange={(value: any) => handleInputChange("userName", value)}
                className='m-1'
                placeHolder='Username'
                required={true}
                onError={!isEmpty(signUpError?.userName)}
                helperText={!isEmpty(signUpError.userName) ? signUpError.userName : ''}
                maxlength={32}
              />
            </div>
            <div className='flex flex-col px-1'>
              <span className='text-sm text-bluegraylight ml-1' >Minimum 5 characters and maximum 32 characters.</span>
              <span className='text-sm text-bluegraylight ml-1' >Can only contains letters, numbers, dashes, periods and underscores.</span>
            </div>
          </div> */}

          <div className="flex flex-wrap mt-2 w-full px-1">
            <div className="w-1/2">
              <TextInput
                label={'PASSWORD'}
                value={formData.password}
                onChange={(value: any) => handleInputChange("password", value)}
                className='m-1'
                placeHolder='Password'
                required={true}
                onError={!isEmpty(signUpError?.password)}
                helperText={!isEmpty(signUpError.password) ? signUpError.password : ''}
                password={true}
              />
            </div>
            <div className="w-1/2">
              <TextInput
                label={'CONFIRM PASSWORD'}
                value={formData.confirmPassword}
                onChange={(value: any) => handleInputChange("confirmPassword", value)}
                className='m-1'
                placeHolder='Confirm password'
                onError={!isEmpty(signUpError?.confirmPassword)}
                helperText={!isEmpty(signUpError.confirmPassword) ? signUpError.confirmPassword : ''}
                password={true}
              />
            </div>
          </div>
          <ul className='flex flex-col px-6 py-1'>
            <li className={classNames('text-sm  ml-1', formData.password.length < 8 ? 'text-manhattan700' : 'text-bluegraylight')}>Minimum 8 characters.</li>
            <li className={classNames('text-sm  ml-1', Dont_Start_number(formData.password) ? 'text-manhattan700' : 'text-bluegraylight')}>Dont start with a number.</li>
            <li className={classNames('text-sm  ml-1', IncludeSpecialCharecters(formData.password) ? 'text-manhattan700' : 'text-bluegraylight')}>Include a special character.</li>
            <li className={classNames('text-sm  ml-1', FindCapitalLetter(formData.password) ? 'text-manhattan700' : 'text-bluegraylight')}>one CAPITAL letter.</li>
          </ul>

          <div className='flex flex-row items-center px-2 py-1 mb-4'>
            <button
              className="bg-primary  text-sm text-white  border  border-darkBlueBorder font-bold py-3 px-6 h-11 rounded-md focus:outline-none focus:shadow-outline min-w-[120px]"
              type="submit"
              onClick={onSubmitFunction}
            >
              REGISTER
            </button>
            <button
              type="button"
              className=" text-sm border border-brdrbtn1 font-bold py-2.5 px-4 rounded-md  ml-3 min-w-[120px] cancel-btn focus:border-brdrbtn1 "
              onClick={() => navigate('/', { replace: true })}
            >
              Cancel
            </button>
          </div>
        </>
      )}

    </form>
  );
};


export default Form;