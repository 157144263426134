import * as React from 'react';

import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';

interface IKendoDropdown {
  DropdownContent: Array<any>
  defaultItem?: string,
  onChangeFunction?: any,
  controlled?: boolean,
  value?: any,
  textField?: any,
  dataItemKey?: any,
  onError?: boolean,
  helperText?: string
}

const KendoSelector = (props: IKendoDropdown) => {


  const { DropdownContent, defaultItem, onChangeFunction, controlled, value, textField, dataItemKey, onError, helperText } = props;

  const itemRender = (
    li: any,
  ) => {
    const itemChildren = (
      <span className={'text-sm min-h-[1.8rem] items-center flex text-primarytext py-1'} >
        {li.props.children}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };
  
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const defaultItems = { label: defaultItem, value: '' };
  return (
    <div className="w-full " >
      <div className=" w-full text-kendo k-state-selected">
        <DropDownList
          value={value}
          opened={controlled}
          className={classNames(
            onError? 'in-error' : '', 
            value?.value === ''? 'placeholder' : ''
          )}
          data={[defaultItems, ...DropdownContent]}
          itemRender={itemRender}
          onChange={onChangeFunction}
          textField={textField}
          dataItemKey={dataItemKey}
          fillMode={"outline"}
        />
        {helperText && <span className='block text-manhattan700 text-xs m-1'>{helperText}</span>}
      </div>
    </div>
  );
}

export default KendoSelector;