import { useState } from 'react';
//assets
import hidepaswordIcon from '../../assets/image/hidepassword.svg'
import showPasswordIcon from '../../assets/image/showpassword.svg'
//validation
import { AllowIfIsNumber } from '../../helpers/validation';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
interface ITextInputProps {
  label?: string,
  className?: string,
  onChange: Function,
  value: any,
  password?: any,
  passwordIcon?: boolean,
  loading?: boolean
  onError?: any,
  disabled?: boolean,
  maxlength?: number;
  placeHolder?: string;
  nonEditable?: boolean;
  required?: boolean,
  helperText?: string,
  AllowOnlyNumber?: any,
  onFocus?: any,
  error?: any,
  pattern?: string,
  autoComplete?: boolean
}

function TextInput(props: ITextInputProps) {
  const {
    label,
    className,
    onChange,
    value,
    password,
    AllowOnlyNumber,
    passwordIcon,
    loading,
    onFocus,
    error,
    onError,
    disabled,
    maxlength,
    placeHolder,
    nonEditable,
    required,
    helperText,
    pattern,
    autoComplete
  } = props;


  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState(false);
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const onChangeEvent = (value: any) => {
    if (AllowIfIsNumber(value)) onChange(value)
  }

  return (
    <div className={className}>
      <div className="flex flex-col relative text-primarytext">
        <div className="flex flex-row justify-between items-center">
          <div className="inline-block text-xs font-semibold  relative mb-1">
            {label}
            {required && <span className='-right-4 -top-2 left-auto text-manhattan700  absolute text-sm' >*</span>}
          </div>
          {passwordIcon && <div className="h-5 w-5 mr-3">
            {(
              <button
                tabIndex={-1}
                type="button"
                className="absolute bottom-2 h-6 w-6 bg-white "
                onClick={() => setShowPassword(!showPassword)}
              ><img src={showPassword ? showPasswordIcon : hidepaswordIcon} alt="notes" />
              </button>
            )}
          </div>}
        </div>
        <input
          placeholder={placeHolder}
          value={value}
          type={!password || showPassword ? "text" : "password"}
          autoComplete={autoComplete ? "true" : "cc-given-name"}
          maxLength={maxlength}
          className={classNames(
            'px-3',
            (onError || isFocused) ? 'border-manhattan600 border no-box-shadow' : 'border border-t-1',
            ' block w-full border-bluegray200 rounded-md  focus:outline-none  sm:text-sm focus:border focus:border-manhattan600',
            isFocused && 'border-manhattan600'
          )}
          style={{
            minHeight: '44px',
          }}
          onChange={(event) => AllowOnlyNumber ? onChangeEvent(event?.target?.value) : onChange(event?.target?.value)}
          disabled={nonEditable}
          onFocus={() => {
            setIsFocused(true);
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            setIsFocused(false);
            // if (onError) onError();
          }}
          pattern={pattern}
        />
      </div>
      {helperText && <span className='block text-manhattan700 text-xs m-1'>{helperText}</span>}
    </div>
  );
}
export default TextInput;
