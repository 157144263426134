const homeURL = process.env.REACT_APP_API_URL || '';//  'https://dev.yourchildsday.com';
const ENV = process.env.REACT_APP_ENVIRONMENT || '';
interface iConfigWeb {
  baseURL: string;
  HomeUrl: string;
  ENV: string;
  enableOnlineStatus: boolean;
}
const configWeb: iConfigWeb = {
  baseURL: `${homeURL}/WebApi/`,
  HomeUrl: homeURL,
  ENV,
  enableOnlineStatus: true,
};

export default configWeb;
