import { useSelector } from 'react-redux';

interface ILoaderProps {
  loading?: boolean,
}

function Loader({ loading }: ILoaderProps) {
  const loadingData = useSelector((state: any) => state?.global?.loading);
  if(!loadingData && !loading) return null;
  return (
    <div className="loader-wrapper">
      <div className="loader"></div>
    </div>
  );
}



export default Loader;
