const getCompanyLists = [
  {
    "CompanyID": 1,
    "CompanyName": "Building Futures Pty Ltd",
    "LegalName": null,
    "SignInEnabled": false,
    "CommunicationEnabled": false,
    "UsedForAllServices": false,
    "CentreList": null,
    "ABN": null,
    "ProviderID": null,
    "ProviderEntityType": null,
    "ACECQARegistrationCode": null,
    "ACECQAExemptionReason": null,
    "CompanyType": null,
    "IsforProfit": false,
    "IsCharitable": false,
    "CharitAsofDateofEvent": null,
    "CharitAsofDateofEventString": null,
    "CompanyForProfitIsCharitable": null,
    "CompanyUltimateHoldingEntity": null,
    "CompanyExternalAdministrator": null,
    "Phone": null,
    "Mobile": null,
    "Email": null,
    "EmailforallCentre": null,
    "ReferenceTable": null,
    "GenericContact": null,
    "GenericAddressList": null,
    "GenericAddressxml": null,
    "GenericCCSApproval": null,
    "GenericNote": null,
    "Financial": null,
    "companypartner": null,
    "companyfinancialstatus": null,
    "genericsupprtingdocuments": null,
    "LastModifiedDate": null
  },
  {
    "CompanyID": 3,
    "CompanyName": "KINGSTHORPE LABOUR CO PTY LTD ATF YELWOC FAMILY TRUST",
    "LegalName": null,
    "SignInEnabled": false,
    "CommunicationEnabled": false,
    "UsedForAllServices": false,
    "CentreList": null,
    "ABN": null,
    "ProviderID": null,
    "ProviderEntityType": null,
    "ACECQARegistrationCode": null,
    "ACECQAExemptionReason": null,
    "CompanyType": null,
    "IsforProfit": false,
    "IsCharitable": false,
    "CharitAsofDateofEvent": null,
    "CharitAsofDateofEventString": null,
    "CompanyForProfitIsCharitable": null,
    "CompanyUltimateHoldingEntity": null,
    "CompanyExternalAdministrator": null,
    "Phone": null,
    "Mobile": null,
    "Email": null,
    "EmailforallCentre": null,
    "ReferenceTable": null,
    "GenericContact": null,
    "GenericAddressList": null,
    "GenericAddressxml": null,
    "GenericCCSApproval": null,
    "GenericNote": null,
    "Financial": null,
    "companypartner": null,
    "companyfinancialstatus": null,
    "genericsupprtingdocuments": null,
    "LastModifiedDate": null
  },
  {
    "CompanyID": 6,
    "CompanyName": "Test5",
    "LegalName": null,
    "SignInEnabled": false,
    "CommunicationEnabled": false,
    "UsedForAllServices": false,
    "CentreList": null,
    "ABN": null,
    "ProviderID": null,
    "ProviderEntityType": null,
    "ACECQARegistrationCode": null,
    "ACECQAExemptionReason": null,
    "CompanyType": null,
    "IsforProfit": false,
    "IsCharitable": false,
    "CharitAsofDateofEvent": null,
    "CharitAsofDateofEventString": null,
    "CompanyForProfitIsCharitable": null,
    "CompanyUltimateHoldingEntity": null,
    "CompanyExternalAdministrator": null,
    "Phone": null,
    "Mobile": null,
    "Email": null,
    "EmailforallCentre": null,
    "ReferenceTable": null,
    "GenericContact": null,
    "GenericAddressList": null,
    "GenericAddressxml": null,
    "GenericCCSApproval": null,
    "GenericNote": null,
    "Financial": null,
    "companypartner": null,
    "companyfinancialstatus": null,
    "genericsupprtingdocuments": null,
    "LastModifiedDate": null
  },
  {
    "CompanyID": 7,
    "CompanyName": "Demos",
    "LegalName": null,
    "SignInEnabled": false,
    "CommunicationEnabled": false,
    "UsedForAllServices": false,
    "CentreList": null,
    "ABN": null,
    "ProviderID": null,
    "ProviderEntityType": null,
    "ACECQARegistrationCode": null,
    "ACECQAExemptionReason": null,
    "CompanyType": null,
    "IsforProfit": false,
    "IsCharitable": false,
    "CharitAsofDateofEvent": null,
    "CharitAsofDateofEventString": null,
    "CompanyForProfitIsCharitable": null,
    "CompanyUltimateHoldingEntity": null,
    "CompanyExternalAdministrator": null,
    "Phone": null,
    "Mobile": null,
    "Email": null,
    "EmailforallCentre": null,
    "ReferenceTable": null,
    "GenericContact": null,
    "GenericAddressList": null,
    "GenericAddressxml": null,
    "GenericCCSApproval": null,
    "GenericNote": null,
    "Financial": null,
    "companypartner": null,
    "companyfinancialstatus": null,
    "genericsupprtingdocuments": null,
    "LastModifiedDate": null
  },
  
]

const getCentreLists :any= [
  {
    "CentreID": 1,
    "Name": "Building Futures Montessori - Forest Lake",
    "Name2": null,
    "ABN": null,
    "ACN": null,
    "Address1": null,
    "Address2": null,
    "Postcode": null,
    "State": null,
    "Phone": null,
    "Fax": null,
    "Mobile": null,
    "Email": null,
    "BankAccountName": null,
    "BankAccountBSB": null,
    "BankAccountNumber": null,
    "BankAccountDepositPrefix": null,
    "LastUpdate": null,
    "SMTPHost": null,
    "Website": null,
    "FTPHost": null,
    "FTPUsername": null,
    "FTPPassword": null,
    "CatUsername": null,
    "CatPassword": null,
    "CatApiID": null,
    "CompanyID": 1,
    "CentreTypeID": 0,
    "RoomList": null,
    "CompanyName": "Building Futures Pty Ltd",
    "Financial": null,
    "LocationOfRecords": null,
    "Address": null,
    "Closure": null,
    "NameAsOfDate": null,
    "ServiceID": null,
    "ServiceType": null,
    "OpeningTime": null,
    "OpeningDateTime": null,
    "ClosingTime": null,
    "ClosingDateTime": null,
    "NumberOfWeeksPerYear": 0,
    "NumberOfWeeksPerYearAsOfDate": null,
    "LicensedNumberPlaces": 0,
    "LicensedNumberPlacesDateOfEvent": null,
    "ACECQARegistrationCode": null,
    "ACECQAExemptionReason": null,
    "ACCSPercentage": {
      "id": 0,
      "CompanyID": 0,
      "CentreID": 0,
      "ChildID": 0,
      "EnrolmentID": null,
      "QueryDate": null,
      "ACCSPercentageCap": null,
      "NumberOfChildAtRisk": null,
      "PercentageOfChildAtRisk": null,
      "CertificateOrApplicationToBeSubmitted": null,
      "Companies": null,
      "Centre": null,
      "Child": null
    },
    "GeneralGenericContact": {
      "id": 0,
      "ReferenceTable": null,
      "ReferenceID": 0,
      "Function": null,
      "Phone": null,
      "Mobile": null,
      "Email": null,
      "EmailForAllService": 0,
      "URL": null,
      "StartDate": null,
      "LastModifiedDate": null,
      "EmailforallCentre": false,
      "GenericContactID": 0
    }
  },
  {
    "CentreID": 2,
    "Name": "Building Futures Montessori - Blackstone",
    "Name2": null,
    "ABN": null,
    "ACN": null,
    "Address1": null,
    "Address2": null,
    "Postcode": null,
    "State": null,
    "Phone": null,
    "Fax": null,
    "Mobile": null,
    "Email": null,
    "BankAccountName": null,
    "BankAccountBSB": null,
    "BankAccountNumber": null,
    "BankAccountDepositPrefix": null,
    "LastUpdate": null,
    "SMTPHost": null,
    "Website": null,
    "FTPHost": null,
    "FTPUsername": null,
    "FTPPassword": null,
    "CatUsername": null,
    "CatPassword": null,
    "CatApiID": null,
    "CompanyID": 1,
    "CentreTypeID": 0,
    "RoomList": null,
    "CompanyName": "Building Futures Pty Ltd",
    "Financial": null,
    "LocationOfRecords": null,
    "Address": null,
    "Closure": null,
    "NameAsOfDate": null,
    "ServiceID": null,
    "ServiceType": null,
    "OpeningTime": null,
    "OpeningDateTime": null,
    "ClosingTime": null,
    "ClosingDateTime": null,
    "NumberOfWeeksPerYear": 0,
    "NumberOfWeeksPerYearAsOfDate": null,
    "LicensedNumberPlaces": 0,
    "LicensedNumberPlacesDateOfEvent": null,
    "ACECQARegistrationCode": null,
    "ACECQAExemptionReason": null,
    "ACCSPercentage": {
      "id": 0,
      "CompanyID": 0,
      "CentreID": 0,
      "ChildID": 0,
      "EnrolmentID": null,
      "QueryDate": null,
      "ACCSPercentageCap": null,
      "NumberOfChildAtRisk": null,
      "PercentageOfChildAtRisk": null,
      "CertificateOrApplicationToBeSubmitted": null,
      "Companies": null,
      "Centre": null,
      "Child": null
    },
    "GeneralGenericContact": {
      "id": 0,
      "ReferenceTable": null,
      "ReferenceID": 0,
      "Function": null,
      "Phone": null,
      "Mobile": null,
      "Email": null,
      "EmailForAllService": 0,
      "URL": null,
      "StartDate": null,
      "LastModifiedDate": null,
      "EmailforallCentre": false,
      "GenericContactID": 0
    }
  },
  {
    "CentreID": 3,
    "Name": "Building Futures Montessori - Wavell Heights",
    "Name2": null,
    "ABN": null,
    "ACN": null,
    "Address1": null,
    "Address2": null,
    "Postcode": null,
    "State": null,
    "Phone": null,
    "Fax": null,
    "Mobile": null,
    "Email": null,
    "BankAccountName": null,
    "BankAccountBSB": null,
    "BankAccountNumber": null,
    "BankAccountDepositPrefix": null,
    "LastUpdate": null,
    "SMTPHost": null,
    "Website": null,
    "FTPHost": null,
    "FTPUsername": null,
    "FTPPassword": null,
    "CatUsername": null,
    "CatPassword": null,
    "CatApiID": null,
    "CompanyID": 1,
    "CentreTypeID": 0,
    "RoomList": null,
    "CompanyName": "Building Futures Pty Ltd",
    "Financial": null,
    "LocationOfRecords": null,
    "Address": null,
    "Closure": null,
    "NameAsOfDate": null,
    "ServiceID": null,
    "ServiceType": null,
    "OpeningTime": null,
    "OpeningDateTime": null,
    "ClosingTime": null,
    "ClosingDateTime": null,
    "NumberOfWeeksPerYear": 0,
    "NumberOfWeeksPerYearAsOfDate": null,
    "LicensedNumberPlaces": 0,
    "LicensedNumberPlacesDateOfEvent": null,
    "ACECQARegistrationCode": null,
    "ACECQAExemptionReason": null,
    "ACCSPercentage": {
      "id": 0,
      "CompanyID": 0,
      "CentreID": 0,
      "ChildID": 0,
      "EnrolmentID": null,
      "QueryDate": null,
      "ACCSPercentageCap": null,
      "NumberOfChildAtRisk": null,
      "PercentageOfChildAtRisk": null,
      "CertificateOrApplicationToBeSubmitted": null,
      "Companies": null,
      "Centre": null,
      "Child": null
    },
    "GeneralGenericContact": {
      "id": 0,
      "ReferenceTable": null,
      "ReferenceID": 0,
      "Function": null,
      "Phone": null,
      "Mobile": null,
      "Email": null,
      "EmailForAllService": 0,
      "URL": null,
      "StartDate": null,
      "LastModifiedDate": null,
      "EmailforallCentre": false,
      "GenericContactID": 0
    }
  },
    
]
const staticTitle: any = {
  '/ForgotPassword': 'Forgot Password',
  '/Register': 'Create a New Account',
  '/resetpassword': 'Forgot Passowrd',
};

export {getCentreLists , getCompanyLists, staticTitle}