import { isEmpty } from "lodash";

export const Dont_Start_number = (value: any) => {
  const Origin = (value).substring(0, 1)
  if ((/^(?![0-9_])\w+$/).test(Origin)) {
    return false
  }
  else return true
}

export const IncludeSpecialCharecters = (value: string) => {
  let specialChars = /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/;
  if (specialChars.test(value)) {
    return false
  }
  else return true
}

export const FindCapitalLetter = (value: string) => {
  let CapitalLetter = /[A-Z]/;
  if (CapitalLetter.test(value)) return false
  else return true
}
export const SignUpValidation = (reqObj: any) => {
  const errors: any = {};
  if (isEmpty(reqObj.firstName)) {
    errors['firstName'] = "The First name field is required."
  }
  if (isEmpty(reqObj.lastName)) {
    errors['lastName'] = "The Last name field is required."
  }
  if (isEmpty(reqObj.email)) {
    errors['email'] = "The Email Address field is required."
  }
  if (!isEmpty(reqObj?.email)) {
    if (!(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/).test(reqObj?.email)) {
      errors['email'] = 'Please enter valid email address.'
    }
  }
  if (isEmpty(reqObj.mobileNumber)) {
    errors['mobileNumber'] = "The Mobile number field is required."
  }
  
  if (isEmpty(reqObj.siteAccess)) {
    errors['siteAccess'] = "Site access is required."
  }
  if (isEmpty(reqObj.companyid)) {
    errors['companyName'] = "Company is required."
  }
  if (isEmpty(reqObj.centreid)) {
    errors['centreName'] = "Centre is required."
  }
  if (!isEmpty(reqObj?.mobileNumber)) {
    if (reqObj.mobileNumber.length < 10) {
      errors['mobileNumber'] = "Please enter valid Mobile number."
    }
  }
  // if (isEmpty(reqObj.userName)) {
  //   errors['userName'] = "The Username field is required."
  // }
  // if (!isEmpty(reqObj.userName)) {
  //   if (!(/^\w+$/).test(reqObj?.userName)) {
  //         errors['userName'] = "Please enter valid UserName."
  //   }
  //   if (reqObj.userName.length < 5) {
  //     errors['userName'] = "The Username must be at least 5 characters long."
  //   }
  // }
  if (isEmpty(reqObj.password)) {
    errors['password'] = "The Password field is required."
  }
  if (!isEmpty(reqObj?.password)) {
    if ((reqObj?.password || "").length < 8 ) {
      errors['password'] = "Please enter valid password"
    }
    if (Dont_Start_number(reqObj?.password) || IncludeSpecialCharecters(reqObj?.password) || FindCapitalLetter(reqObj?.password)) {
      errors['password'] = "Please enter valid password"
    }
  }
  if (!isEmpty(reqObj?.password) && (isEmpty(reqObj.confirmPassword || (reqObj.password !== reqObj.confirmPassword)))) {
    errors['confirmPassword'] = "The password and confirmation password do not match."
  }
  if ((reqObj.password !== reqObj.confirmPassword)) {
    errors['confirmPassword'] = "The password and confirmation password do not match."
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}



export const AllowIfIsNumber = (value: any) => {
  const re = /^[0-9\b]+$/;
  if (value === '' || re.test(value)) {
    return true
  }
}

export const validateOptions = (value: any) => {
  if (value !== null
&& value !== 'null'
&& value !== '0001-01-01T00:00:00'
&& value !== undefined
&& value !== ''
&& value !== Number.isNaN
&& value !== 'NaN') {
    return true;
  }

  return false;
};

export const ResetPasswordValidation = (ReqObj: any) => {
  const errors: any = {};
  if (isEmpty(ReqObj?.password)) {
    errors['password'] = "The Password field is required."
  }
  if (!isEmpty(ReqObj?.password)) {
    if ((ReqObj?.password || "").length < 8 ) {
      errors['password'] = "Please enter valid password"
    }
    if (Dont_Start_number(ReqObj?.password) || IncludeSpecialCharecters(ReqObj?.password) || FindCapitalLetter(ReqObj?.password)) {
      errors['password'] = "Please enter valid password"
    }
  }
  if (!isEmpty(ReqObj?.password) && (isEmpty(ReqObj?.confirmPassword || (ReqObj?.password !== ReqObj?.confirmPassword)))) {
    errors['confirmPassword'] = "The password and confirmation password do not match."
  }
  if ((ReqObj?.password !== ReqObj?.confirmPassword)) {
    errors['confirmPassword'] = "The password and confirmation password do not match."
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}

export const EmailValidation = (email: string)=>{
  if (!isEmpty(email)) {
    if (!(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/).test(email)) {
      return false
    }
    else return true
  }
}