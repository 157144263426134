import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
//components
import TextInput from '../../Components/FormControlls/TextInput';
import Popup from '../../Components/popup';
//redux
import { CreateUserInSignIN, GetUserDetailToAccess, SetSiteToAccess, getUserRole, setLoader } from '../../redux/action';
import { groupBy, uniqBy } from 'lodash';
import classNames from '../../helpers/common';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { EmailValidation } from '../../helpers/validation';

const Form = () => {
   const [formData, setFormData] = useState({
      email: "",
      password: "",
   });
   const [rememberMe, setRememberMe] = useState(false);
   const [OpenPopup, setOpenPopup] = useState<any>(false)
   const [onError, setOnError] = useState(false);

   const SiteUrlLists = useSelector((state: any) => state?.global?.SiteUrlLists);
   const dispatch = useDispatch<any>();
   const [validateState, setState] = useState({ signUpError: {} })
   const { signUpError }: any = validateState;
   const [searchParams] = useSearchParams();
   const lastAccessedUrl = searchParams.get('lastAccessedUrl');
   // const groupBySelectedSite: any = groupBy(SiteUrlLists, 'site_to_access')

   const handleInputChange = (type: string, value: any) => {
      setState({ signUpError: { ...signUpError, [type]: "" } })
      setFormData({ ...formData, [type]: value });
   };


   const onBtnClick = (item: any) => {
      let accessToken = localStorage.getItem("accessToken");
      let refreshToken = localStorage.getItem("refreshToken");

      let PostReqForUserAccess = {
         "accessToken": accessToken,
         "siteToAccess": item?.site_to_access,
         "roleId": item?.role_id,
         "refreshToken": refreshToken
      }
      localStorage.setItem('SiteToAccess', item?.site_to_access);
      setOpenPopup(false)
      dispatch(setLoader(true))
      dispatch(GetUserDetailToAccess(PostReqForUserAccess)).then((res: any) => {
         if (res?.status === 200 && res?.data?.status) {
            dispatch(SetSiteToAccess(item?.site_to_access)).then(() => {
               dispatch(setLoader(false));               
               localStorage.setItem('UserAccessToken', res.data.accessToken)
               localStorage.setItem('UserIDWhoIsLoggedIn', res.data.user.userId)
               window.open(`${res.data.redirectUrl}&LastAccessedUrl=${lastAccessedUrl || ''}`, "_self");
            })
         }
      })

   }

   const handleSignIn = async (e: any) => {
      e.preventDefault();
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (!formData.email || regex.test(formData.email.trim()) === false || !formData.password) {
         setOnError(true);
         return;
      }
      let postReq = {
         "username": formData?.email,
         "password": formData?.password,
      }
      setOnError(false);
      dispatch(setLoader(true))
      dispatch(CreateUserInSignIN(postReq)).then((res: any) => {
         if (res?.status === 200 && res?.data?.errorMessage === null || res?.data?.errorMessage === "") {
            const accessToken = res?.data?.data?.accessToken
            const refreshToken = res?.data?.data?.refreshToken
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            let postReq = {
               "accessToken": accessToken
            }
            dispatch(getUserRole(postReq)).then((res: any) => {
               if (res?.status === 200 && res?.data?.data) {
                  let UniqByRolesBasedOnSiteAccess = uniqBy(res?.data?.data?.roles, 'site_to_access');
                  if ((UniqByRolesBasedOnSiteAccess || []).length === 1) {
                     let PostReqForUserAccess = {
                        "accessToken": accessToken,
                        "siteToAccess": res?.data?.data?.roles[0]?.site_to_access,
                        "roleId": res?.data?.data?.roles[0]?.role_id,
                        "refreshToken": refreshToken
                     }
                     localStorage.setItem('SiteToAccess', res?.data?.data?.roles[0]?.site_to_access);
                     dispatch(setLoader(true))
                     dispatch(GetUserDetailToAccess(PostReqForUserAccess)).then((res: any) => {
                        if (res?.status === 200 && res?.data?.status) {
                           dispatch(SetSiteToAccess(PostReqForUserAccess?.siteToAccess)).then(() => {
                              dispatch(setLoader(false));
                              localStorage.setItem('UserAccessToken', res.data.accessToken)
                              localStorage.setItem('UserIDWhoIsLoggedIn', res.data.user.userId)
                              window.open(`${res.data.redirectUrl}&LastAccessedUrl=${lastAccessedUrl || ''}`, "_self");
                           });
                        }
                     })
                  }
                  else {
                     setOpenPopup(true)
                     dispatch(setLoader(false))
                  }
               }
            })
         }
      });
   };

   const onSubmitFunction = (e: any) => {
      e.preventDefault();
      handleSignIn(e)
   }
   const getValidationMessage = (fieldName: string) => {
      if (onError) {
         if (fieldName === "email") {
            if (!formData.email) {
               return 'The Email field is required.';
            }
            if (!EmailValidation(formData.email)) {
               return 'Please enter valid email address.';
            }
         }
         else {
            if (!formData.password) {
               return 'The Password field is required.';
            }
         }
      }
      return ''
   }
   return (
      <form onSubmit={onSubmitFunction} className="flex flex-wrap content-center w-full text-primarytext min-w-[391px] max-w-[391px]">
         <Popup openPopup={OpenPopup} setOpenPopup={() => setOpenPopup(!OpenPopup)} title={"Select a centre to visit"} CloseIcon={true}
         contentClasses={'!p-0'}
         >
            <div className=' flex flex-col max-h-96 overflow-y-scroll no-scrollbar overscroll-none w-full'>
               {(uniqBy(SiteUrlLists, 'site_to_access') || []).map((item: any) => {
                  return <div className={classNames('text-primarytext  cursor-pointer py-3 px-4 font-bold text-base flex flex-row justify-between items-center hover:bg-gray-100')} onClick={() => onBtnClick(item)}>
                    <div className="w-11/12"> {(item?.company_Name && item?.centre_Name) ? `${item?.company_Name} - ${item?.centre_Name}` : item?.site_to_access} </div>
                    <div className="w-1/12 flex justify-end"> <ChevronRightIcon className="h-7 w-7" /></div>
                  </div>
               })}
            </div>
         </Popup>

         <div >
            <div className="flex relative top-7">
               <h2 className="text-2xl font-bold mb-15 text-custom-colormt-2">Sign In</h2>
            </div>
            <TextInput
               label={"E-MAIL ADDRESS"}
               value={formData.email}
               className="w-96 mb-4 min-width-25"
               onChange={(value: any) => handleInputChange("email", value)}
               onFocus={() => setOnError(false)}
               onError={onError && getValidationMessage("email") != ""}
               autoComplete={true}
               helperText={getValidationMessage("email")}
            />
            <TextInput
               label={"PASSWORD"}
               value={formData.password}
               passwordIcon={true}
               password={true}
               className="w-96 min-width-25"
               onChange={(value: any) => handleInputChange("password", value)}
               onFocus={() => setOnError(false)}
               onError={onError && getValidationMessage("password") != ""}
               autoComplete={true}
               helperText={getValidationMessage("password")}
            />
            <div className="flex flex-row w-96  items-center min-width-25 justify-between relative  py-6">
               <div className="flex flex-row items-center">
                  {/* <div className='checkbox  items-start'>
                     <input
                        id="RememberMe"
                        type="checkbox"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                     />
                     <label htmlFor="RememberMe" className="text-sm font-semibold items-start ml-2 cursor-pointer">
                        REMEMBER ME
                     </label>
                  </div> */}
               </div>
               <Link to="/ForgotPassword" className="text-sm item-start text-manhattan700">
                  Forgot your password?
               </Link>
            </div>
            <div className="flex py-1"></div>
            <button
               type="submit"
               className="mb-9 flex items-center justify-center text-white text-sm font-bold shadow rounded border border-brdrbtn1 h-11 w-96 min-width-25 outline-none bg-primary"
               onClick={onSubmitFunction}
            >
               SIGN IN
            </button>
         </div>
      </form>
   );
};

export default Form;
