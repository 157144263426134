import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import classNames from '../helpers/common';

interface IPopupProps {
  title?: string,
  children: JSX.Element[] | JSX.Element,
  openPopup: boolean,
  setOpenPopup?: any,
  temp?: any,
  hasValidation?: boolean,
  onBtnClick?: Function,
  CloseIcon?: boolean,
  contentClasses?: string,
}

function Popup(props: IPopupProps) {
  const {
    title, children, openPopup, setOpenPopup, temp, hasValidation, onBtnClick, CloseIcon, contentClasses
  } = props;
  const currentChildState = temp?.current?.GetStates() || {};
  return (
    <Transition.Root show={openPopup} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpenPopup}>
        <div className="items-end justify-center min-h-screen pt-4 sm:pb-20 text-center block p-4 sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block bg-white rounded-sm  text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle sm:max-w-md w-full ">
              <div className={`${!title ? 'hidden' : 'flex'} flex flex-col text-primarymedium border-b border-borderbody text-sm py-2`}>
                <div className="flex flex-row items-center justify-between px-4">
                  <div className="text-lg font-semibold">
                    {title}
                  </div>
                  {(title === 'Sleep Records' || CloseIcon)
                    && (
                      <button
                        type="button"
                        className="flex items-center justify-center bg-gradient-to-t from-brgrdbtn1 to-brgrdbtn2 shadow rounded border border-brdrbtn1 w-7 h-7 outline-none"
                        onClick={() => { setOpenPopup(false); }}
                      >
                        <XIcon width={CloseIcon ? '20' : '30'} />
                      </button>
                    )}
                </div>
              </div>
              <div className={classNames("flex text-primarymedium p-4 ", contentClasses)}>
                {children}
              </div>
              {onBtnClick &&
                (
                  <div className="bg-white relative flex flex-row items-center justify-end pt-4 px-4 space-x-2 border-t border-borderbody pb-4">
                    <button
                      type="button"
                      className="flex items-center justify-center bg-gradient-to-t text-primary text-sm font-medium from-brgrdbtn1 to-brgrdbtn2 shadow rounded border border-brdrbtn1 h-11 w-24 outline-none"
                      onClick={() => { setOpenPopup(false); }}
                    >
                      Cancel

                    </button>

                    <button
                      type="button"
                      className="text-white text-sm font-bold h-11 w-32 bg-oracle600 shadow border border-oracle700 rounded"
                      onClick={() => {
                        if (onBtnClick) onBtnClick()
                      }}
                      disabled={currentChildState.ActiveButton === -1}
                    >
                      OK
                    </button>

                  </div>
                )
              }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default Popup;
