/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header className=" text-white relative top-7 py-2.5">
      <Link to="/" title="Building Futures - logo" className="flex-shrink-0 flex items-center ">
        <img className="block w-auto" src="https://dev.yourchildsday.com/Content/Images/Logo-new.svg" alt="Workflow" />
        <p className="font-semibold text-base sm:text-lg md:text-xl text-custom-color">Your Child's Day</p>
      </Link>
    </header>
  );
};

export default Header;