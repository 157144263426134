import axios from 'axios';
import config from '../config';
import { userInfo } from 'os';

export const CreateUserAPI = (UserInfo: any) => axios({
  method: 'POST',
  baseURL: config.baseURL,
  data: UserInfo,
  url: `/Auth/createuser`,
});

export const CreateSignAPI = (userData: any) => {
  const token = localStorage.getItem("token");

  return axios({
    method: 'POST',
    baseURL: config.baseURL,
    data: userData,
    url: `/Auth/userauth`
  });
};

export const GetCompanyListsAPI = () => axios({
  method: 'GET',
  baseURL: config.baseURL,
  url: `/Auth/getcentres`,
});

export const ForgotPasswordAPI = (email: any) => axios({
  method: 'POST',
  baseURL: config.baseURL,
  data: email,
  url: `/Auth/forgotpasswordauth`
})

export const ResetpasswordAPI = (postReq: any) => axios({
  method: 'POST',
  baseURL: config.baseURL,
  data: postReq,
  url: `/Auth/resetpasswordauth`
})

export const UserConfirmationAPI = (email: any) => axios({
  method: 'POST',
  baseURL: config.baseURL,
  data: email,
  url: `/Auth/userconfirmation`
})

export const GetUserRoleAPI = (postReq: any) => axios({
  method: 'POST',
  baseURL: config.baseURL,
  data: postReq,
  url: `/Auth/getuserrole`
})

export const GetUserDetailsToAccessAPI = (postReq: any) => axios({
  method: 'POST',
  baseURL: config.baseURL,
  data: postReq,
  url: `/Auth/GetUserDetailToAccess`
})

export const ResendConfirmationAPI = (postReq: any) => axios({
  method: 'POST',
  baseURL: config.baseURL,
  data: postReq,
  url: `/Auth/ResendConfirmationMail`
})
export const SetSiteToAccessAPI = (sitetoaccess: any) => axios({
  method: 'GET',
  baseURL: config.HomeUrl,
  url: `/v1/Account/SetCookies?sitetoaccess=${sitetoaccess}`
});