import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification';
import { handleToastNotification } from '../redux/action';
/* redux  */



const position = {
  topRight: {
    top: 20,
    right: 20,
  },
};


function Toast() {

  const toastInfo:any = useSelector((state: any) => state?.global?.toastInfo)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (toastInfo?.status) {
      setTimeout(() => {
        handleNotify();
      }, 5000);
    }
  }, [toastInfo]);

  const handleNotify = () => {
    dispatch(handleToastNotification({
      type: '',
      message: '',
      status: false,
      isTransition: false,
    }));
  };

  if (!toastInfo?.type || !toastInfo?.message) return <div />;

  return (
    <NotificationGroup>
      <Notification
        key={toastInfo?.type}
        type={{
          style: toastInfo?.type,
          icon: true,
        }}
        closable
        onClose={() => handleNotify()}
        className={["custom-kendo-notification", toastInfo?.size].join(" ")}
      >
        {toastInfo?.message}
      </Notification>
    </NotificationGroup>
  );
}

const mapStateToProps = (state: any) => ({
  toastInfo: state?.global?.toastInfo,
});



export default connect(mapStateToProps)(Toast);
