import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Footer: React.FC = () => {
  const location = useLocation();
  const isRegisterPage = location?.pathname?.toLowerCase()?.includes('register');
  const isForgotPage = location?.pathname?.toLowerCase()?.includes('password');
  const signInLink = <Link className='text-manhattan700 text-sm m-1' to="/Login">{`${'Sign In'} >`}</Link>;
  const createAccountLink = <Link to='/Register' className='text-manhattan700 text-sm m-1'>{`${'Create a new account'} >`} </Link>;

  return (
    <div className="text-custom-color mb-4">
      {isRegisterPage || isForgotPage ? (
        <p className='text-primary text-sm'>Already have an account? {signInLink}</p>
      ) : (
        <>
          <span className='text-primary text-sm   item-start'>Don't have an account?</span>
          {createAccountLink}
        </>
      )}
    </div>
  );
}

export default Footer;