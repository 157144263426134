/* eslint-disable no-underscore-dangle */
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';


import GlobalReducer from './redux/reducer';

export const rootReducer = combineReducers({
  global: GlobalReducer,
});

const store :any = createStore(
  rootReducer,
  applyMiddleware(thunk),
);
export { store };
export type AppDispatch = typeof store.dispatch;
