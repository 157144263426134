/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import AuthLayout from './Layout/AuthLayout';
import './Styles/styles.scss'
import './Styles/kendo.css'


const App: React.FC = () => {
  return (
   <AuthLayout/>);
};

export default App;
