import { useDispatch } from "react-redux";
import { iToastInfo } from "../helpers/AppInterface";
import { CreateUserAPI, GetCompanyListsAPI, CreateSignAPI, ForgotPasswordAPI, ResetpasswordAPI, UserConfirmationAPI, GetUserRoleAPI, GetUserDetailsToAccessAPI, ResendConfirmationAPI, SetSiteToAccessAPI } from "./apicalls";
import { GET_COMPANY_LISTS, ROLES_FOR_SITE_URL, SET_LOADER, SET_TOAST_INFORMATION } from "./types";


export const setLoader = (data: boolean) => ({
  type: SET_LOADER,
  payload: data,
});

export const handleToastNotification = (data: iToastInfo) => ({
  type: SET_TOAST_INFORMATION,
  payload: data,
});

export const CreateUserInSignUp = (postReq: any) => async (dispatch: Function) => {
  try {
    const apiResponse = await CreateUserAPI(postReq).then(async (responseJson) => {
      if(responseJson?.data?.errorMessage){
      dispatch(handleToastNotification({
        type: responseJson?.data?.errorMessage ? 'error' : 'success',
        message: responseJson?.data?.errorMessage ?  responseJson?.data?.errorMessage : responseJson?.data?.responseMessage,
        status: true,
        isTransition: true,
      }));
      }
      return responseJson;
    }).catch((error) => {
      console.log(error);
    });

    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const CreateUserInSignIN = (postReq: any) => async (dispatch: Function) => {
  try {
    const apiResponse = await CreateSignAPI(postReq).then(async (responseJson) => {
      dispatch(handleToastNotification({
        type: responseJson?.data?.errorMessage && 'error' ,
        message: responseJson?.data?.errorMessage,
        status: true,
        isTransition: true,
      }));
      if(responseJson?.data?.errorMessage){
        dispatch(setLoader(false))
      }
      return responseJson;
    }).catch((error) => {
      console.log(error);
    })
    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const StoreCompanyLists = (response: any) => ({
  type: GET_COMPANY_LISTS,
  payload: response,
});

export const GetCompanyLists = () => async (dispatch: Function) => {
  try {
    const apiResponse = await GetCompanyListsAPI().then(async (responseJson) => {
      dispatch(StoreCompanyLists(responseJson));
      return responseJson;
    }).catch((error) => {
      console.log(error);
    });

    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const Forgotpassword = (email: any) => async (dispatch: Function) => {
  try {
    dispatch(setLoader(true))
    const apiResponse = await ForgotPasswordAPI(email).then(async (responseJson: any) => {
      if(responseJson?.data?.errorMessage){
     dispatch(handleToastNotification({
        type: responseJson?.data?.errorMessage ? 'error' : 'success',
        message: responseJson?.data?.errorMessage ?  responseJson?.data?.errorMessage : responseJson?.data?.responseMessage,
        status: true,
        isTransition: true,
      }));}
      return responseJson;
    }).catch((error) => {
      dispatch(handleToastNotification({
        type: 'error',
        message: error?.errorMessage,
        status: true,
        isTransition: true,
      }));
      console.log(error)
    });
    return apiResponse;
  } catch (error: any) {
    dispatch(handleToastNotification({
      type: 'error',
      message: error?.message ,
      status: true,
      isTransition: true,
    }));
    console.log(error);
    return {};
  }
  finally{
    dispatch(setLoader(false))
  }
};

export const ResetPasswordAction = (postReq: any) => async (dispatch :any) => {
  try {
    dispatch(setLoader(true))
    const apiResponse = await ResetpasswordAPI(postReq).then(async (responseJson: any) => {
      if(responseJson?.data?.errorMessage){
      dispatch(handleToastNotification({
        type: responseJson?.data?.errorMessage ? 'error' : 'success',
        message: responseJson?.data?.errorMessage ?  responseJson?.data?.errorMessage : responseJson?.data?.responseMessage,
        status: true,
        isTransition: true,
      }));
    }
      return responseJson;
    }).catch((error) => {
      console.log(error)
      dispatch(handleToastNotification({
        type: 'error',
        message: error?.errorMessage ,
        status: true,
        isTransition: true,
      }));
    });
    return apiResponse;
  } catch (error:any) {
    dispatch(handleToastNotification({
      type: 'error',
      message: error?.message ,
      status: true,
      isTransition: true,
    }));
    console.log(error);
    return {};
  } 
  finally{
    dispatch(setLoader(false))
  }
};


export const UserConfirmation = (postReq: any) => async (dispatch: any) => {
  try {
    const apiResponse = await UserConfirmationAPI(postReq).then(async (responseJson) => {
      if(responseJson?.data?.errorMessage){
        if (!(responseJson?.data?.errorMessage?.includes('expired'))) dispatch(handleToastNotification({
          type: responseJson?.data?.errorMessage ? 'error' : 'success',
          message: responseJson?.data?.errorMessage ?  responseJson?.data?.errorMessage : responseJson?.data?.responseMessage,
          status: true,
          isTransition: true,
          size: 'left-1/2'
        }));
      }
      return responseJson;
    }).catch((error) => {
      console.log(error)
    });
    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
};

const StoreRolesLists = (response: any) => ({
  type: ROLES_FOR_SITE_URL,
  payload: response,
});

export const getUserRole = (postReq: any) => async (dispatch: any) => {
  try {
    const apiResponse = await GetUserRoleAPI(postReq).then(async (responseJson) => {
      if(responseJson?.data?.data){
        dispatch(StoreRolesLists(responseJson?.data?.data))
      }else{
        dispatch(handleToastNotification({
          type: responseJson?.data?.errorMessage ? 'error' : 'success',
          message: responseJson?.data?.errorMessage ?  responseJson?.data?.errorMessage : responseJson?.data?.responseMessage,
          status: true,
          isTransition: true,
        }));
      }
      return responseJson;
    }).catch((error) => {
      console.log(error)
    });
    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
  finally{
    dispatch(setLoader(false))
  }
};

export const GetUserDetailToAccess = (postReq: any) => async (dispatch: any) => {
  try {
    const apiResponse = await GetUserDetailsToAccessAPI(postReq).then(async (responseJson) => {
      dispatch(handleToastNotification({
        type: responseJson?.data?.errorMessage ? 'error' : 'success',
        message: responseJson?.data?.errorMessage ?  responseJson?.data?.errorMessage : responseJson?.data?.responseMessage,
        status: true,
        isTransition: true,
      }));
      dispatch(setLoader(false))
      return responseJson;
    }).catch((error) => {
      console.log(error)
    });
    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const ResendEmailConfirmation = (postReq: any) => async (dispatch: any) => {
  try {
    const apiResponse = await ResendConfirmationAPI(postReq).then(async (responseJson) => {
       dispatch(handleToastNotification({
        type: responseJson?.data?.errorMessage ? 'error' : 'success',
        message: responseJson?.data?.errorMessage ? responseJson?.data?.errorMessage : responseJson?.data?.responseMessage,
        status: true,
        isTransition: true,
        size: 'left-1/2'
      }));
      dispatch(setLoader(false))
      return responseJson;
    }).catch((error) => {
      console.log(error)
    });
    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const SetSiteToAccess = (sitetoaccess:string) => async (dispatch: any) => {
  try {
    const apiResponse = await SetSiteToAccessAPI(sitetoaccess).then(async (responseJson) => {
      return responseJson;
    }).catch((error) => {
      return error;
    });
    return apiResponse;
  } catch (error) {
    console.log(error);
    return {};
  }
}