import { useDispatch, useSelector } from "react-redux"
import { Link, useSearchParams, useNavigate } from "react-router-dom"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
//redux
import { ResendEmailConfirmation, UserConfirmation, setLoader } from "../redux/action"
//components
import Loader from "../Components/Loader"

//assets
import AppleLogo from '../assets/image/AppleLogo.svg'
import GoogleLogo from '../assets/image/GoogleLogo.svg'

const VerifyEmail = () => {
  const [isLoading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [enableResendEmail, setEnableResendEmail] = useState<any>(false)
  const [errorMessageForResendEmail, setErrorMessageForResendEmail] = useState("")
  const [disableResendButton, setDisableResendButton] = useState<any>(false)
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const code = searchParams.get('code'); // "confirmation_code"
  const email = searchParams.get('id'); // "user_name"
  const mounted = useRef(false);

  useEffect(() => {
    const postReq = {
      email: email,
      code: code
    }
    if (!mounted.current) {
      mounted.current = true;
      dispatch(UserConfirmation(postReq)).then((res: any) => {
        setHasError(res.data.statusCode !== null);
        if (res?.data?.errorMessage?.includes('expired')) {
          setEnableResendEmail(true)
          setErrorMessageForResendEmail(res?.data?.errorMessage)
        }
        if (res.data.errorMessage !== null && !res?.data?.errorMessage?.includes('expired')) {
          setTimeout(() => {
            navigate('/');
          }, 5000)
        }
        setLoading(false);
      })
    }
  }, []);

  const handlingResendEmail = () => {
    setDisableResendButton(true)
    const postReq = { userId: email }
    dispatch(setLoader(true))
    dispatch(ResendEmailConfirmation(postReq)).then(() => {
      setLoader(false)
      setTimeout(() => {
        navigate('/');
      }, 5000)
    })
  }

  return <div className='w-full h-full flex sticky top-32 items-center shadow-sm sm:rounded-md sm:overflow-hidden bg-white border border-borderbody full-height'>
    {isLoading ? <Loader loading={isLoading} /> : !hasError && <div className='flex flex-col items-center  justify-center p-8 mx-auto shadow-sm bg-white border border-borderbody'>

      <Link to="/" title="Building Futures - logo" className="flex-shrink-0 flex items-center ">
        <img className="block w-auto" src="https://dev.yourchildsday.com/Content/Images/Logo-new.svg" alt="Workflow" />
        <p className="font-semibold text-xl sm:text-lg md:text-xl text-custom-color py-6">Your Child's Day</p>
      </Link>
      <p className="font-semibold text-xl text-custom-color py-6">Thanks for confirming your email address. </p>
      <p className="text-sm text-primarylight py-2">You can log in once your access is approved by your Centre Administrator. </p>
      <div className='flex flex-row items-center pt-4'>
        <button
          className="bg-white text-base  text-custom-color font-semibold   py-2 px-6 m-2 border border-body hover-primary rounded-md h-24  flex flex-row items-center"
          type="button"
          onClick={() => window.open('https://apps.apple.com/au/app/your-childs-day/id1623159754', '_blank')}
        > <img className="block w-auto" src={AppleLogo} alt="Workflow" />
          <span className=' w-40'>Open Parent App</span>
        </button>
        <button
          className="bg-white text-base  text-custom-color font-semibold   py-2 px-6 m-2 border border-body rounded-md h-24 hover-primary flex flex-row items-center"
          type="button"
          onClick={() => window.open('https://play.google.com/store/apps/details?id=com.innovationtechnologies.yourchildsday', '_blank')}
        > <img className="block w-auto" src={GoogleLogo} alt="Workflow" />
          <span className=' w-40'>Open Parent App</span>
        </button>
        <button
          className="bg-white text-base  text-custom-color font-semibold   py-2 px-6 m-2  border border-body rounded-md h-24 hover-primary flex flex-row items-center"
          type="button"
          onClick={() => window.open('/authentication/Login', '_blank')}
        > <img className="block w-auto" src="https://dev.yourchildsday.com/Content/Images/Logo-new.svg" alt="Workflow" />
          <span className=' w-40'>Educator portal</span>
        </button>
      </div>
    </div>}
    {enableResendEmail && <div className='flex flex-col items-center  justify-center p-8 mx-auto shadow-sm bg-white border border-borderbody gap-6'>
      <Link to="/" title="Building Futures - logo" className="flex-shrink-0 flex items-center ">
        <img className="block w-auto" src="https://dev.yourchildsday.com/Content/Images/Logo-new.svg" alt="Workflow" />
        <p className="font-semibold text-xl sm:text-lg md:text-xl text-custom-color py-6">Your Child's Day</p>
      </Link>
      <span className="text-primarylight text-sm">{errorMessageForResendEmail}</span>
      <button disabled={disableResendButton} type="submit" onClick={handlingResendEmail} className={`mb-9 flex items-center justify-center text-white text-sm font-bold shadow rounded border border-brdrbtn1 h-11 w-52 outline-none mt-6 ${disableResendButton ? "bg-primarylight" : "bg-primary"}`}>
        RESEND EMAIL
      </button>
    </div>}
  </div>
}

export default VerifyEmail